import { useEffect, useRef, useState, FormEvent } from "react";
import { useHistory, useParams } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import { onError } from "../lib/errorLib";
import "./Note.css";
import Image from 'react-bootstrap/Image';
import {Row, Col, Container, ListGroup, Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Button from 'react-bootstrap/Button';
import MemoizedPdf from "../components/MemoizedPdf";

// import Row from 'react-bootstrap/Row';

interface NoteParams {
    id: string
}

export default function Note() {
  // eslint-disable-next-line
  const file = useRef<HTMLFormElement | null>(null);
  const { id } = useParams<NoteParams>();
  // eslint-disable-next-line
  const history = useHistory();
  const [note, setNote] = useState<INote | undefined>(undefined);
  const [folders, setFolders] = useState<Array<any>>([]);
  // eslint-disable-next-line
  const [noteFolders, setNoteFolders] = useState<Array<any>>([]);
  // eslint-disable-next-line
  const [content, setContent] = useState<string>("");
  const [addFolder, setAddFolder] = useState<string>("");
  const [previewType, setPreviewType] = useState<"PDF"|"TEXT"|"IMAGE"|undefined>(undefined)

  useEffect(() => {
    function loadNote() {
      const init = {
        headers: {
          Accept: "application/json"
        }
      };
      return API.get("notes", `/notes/${id}`, init);
    }
    function loadFolders() {
      const init = {
        headers: {
          Accept: "application/json"
        }
      }
      // list folders
      return API.get("notes", "/folders", init)
    }
    function loadNoteFolders() {
      const init = {
        headers: {
          Accept: "application/json"
        }
      }
      return API.get("notes", `/notes/${id}/folders`, init)
    }
    async function onUpdate() {
      // update folder list
      const noteFolderList = await loadNoteFolders();
      setNoteFolders(noteFolderList)
    }

    async function onLoad() {
      try {
        const note = await loadNote();
        if (note.attachment) {
          console.log(note.attachment);
          const ext : string = note.attachment.split(".").pop()
          if (ext.toLowerCase() === "pdf") {
            setPreviewType("PDF")
          }
          if (["png", "jpg"].includes(ext.toLowerCase())) {
            setPreviewType("IMAGE")
          }
          const params = {
            key: note.attachment
          }
          const attachmentURL = await Storage.vault.get(params.key);
          note.attachmentUrl = attachmentURL;
        }

        setNote(note);
        setContent(note.content);
        const folderList = await loadFolders();
        setFolders(folderList)
        const noteFolderList = await loadNoteFolders();
        setNoteFolders(noteFolderList)
      } catch (e) {
        onError(e);
      }
    }
    if (!note) {
      onLoad();
    } else {
      onUpdate()
    }
  }, [id, note]);

  async function addNoteToFolder(e: FormEvent) {
    e.preventDefault()
    const init = {
      headers: {
        Accept: "application/json"
      }
    }
    await API.post("notes", `/folders/${addFolder}/notes/${id}`, init)
  }

  function renderImage(src: string) {
    return (
       <Image src={src} fluid alt="Rendered image..." />
    );
  }

  function renderAttachment() {
    // has a thumbnail
    if (note && note.attachmentThumbnail) {
      return <a href={note.attachmentThumbnail}><Image src={note?.attachmentThumbnail} thumbnail /></a>
    }
    // otherwise render based on the document type
    if (note && note.attachmentUrl && previewType === "PDF") {
      return <>
      <div>
        <span>{note?.content}</span>
      </div>
      <div className="w-100">
        <MemoizedPdf url={note.attachmentUrl} />
      </div>
      </>
    }
    // render image
    if (note && note.attachmentUrl && previewType === "IMAGE") {
      return (
        <>
        {note?.content} <span><a href={note?.attachmentUrl} download><FontAwesomeIcon icon={faDownload}></FontAwesomeIcon></a></span>
        {renderImage(note.attachmentUrl)}
        </>
      )
    }
    // default: just display the note and provide a link to download the original file
    return <>{note?.content} <span><a href={note?.attachmentUrl} download><FontAwesomeIcon icon={faDownload}></FontAwesomeIcon></a></span></>
  }

  return (
    <div className="Note">
    <Container fluid>
      <Row>   
        <Col sm={12} md={8}>
        {renderAttachment()}
        </Col>
        <Col sm={12} md={4}>
          <Row>
            <h3>Folders</h3>
            <Form>
            <Form.Select isValid={addFolder.length > 0 ? true : false} onChange={(e) => {setAddFolder(e.target.value)}}>
              {folders.map((x) => <option value={x.id} key={x.id}>{x.detail.title}</option>)}
            </Form.Select>
            <Button onClick={addNoteToFolder}>Add</Button>
            </Form>
            <h3>Enrichments</h3>
            <ListGroup>
              <ListGroup.Item>OCR</ListGroup.Item>
            </ListGroup>
            <h3>Tags</h3>
            <ListGroup>
              <ListGroup.Item>Item1</ListGroup.Item>    
            </ListGroup>
          </Row>
        </Col>        
      </Row>
    </Container>
    </div>
  );
}

interface INote {
  content: string,
  createdAt: string,
  userId: string,
  attachment?: string | null,
  attachmentUrl?: string,
  attachmentThumbnail?: string
}
