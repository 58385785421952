import React, { useState } from "react";
import { useHistory } from  "react-router-dom";
import config from "../config";
import "./Settings.css";
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";
import { loadStripe, TokenResult } from "@stripe/stripe-js";
import BillingForm from "../components/BillingForm";
import { Elements } from "@stripe/react-stripe-js";

const stripe_key: string = config.STRIPE_PUBLISHABLE_KEY ? config.STRIPE_PUBLISHABLE_KEY : "";
const stripePromise = loadStripe(stripe_key);

function Settings() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleFormSubmit(storage: number, result: TokenResult) {
    if (result.error || typeof result.token === "undefined") {
      onError(result.error);
      return;
    }
    setIsLoading(true);
    try {
      await billUser({
        storage,
        source: result.token.id,
      });
      alert("Your card has been charged successfully!");
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function billUser(details: any) {
    return API.post("notes", "/billing", {
      body: details
    });
  }

  return (
    <div className="Settings">
    <Elements
      stripe={stripePromise}
    >
      <BillingForm isLoading={isLoading} onSubmit={handleFormSubmit} />
    </Elements>
    </div>
  );
}

export default Settings;
