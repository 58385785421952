import { Storage } from "aws-amplify";

interface IStorageVaultResult {
  key: string
}

export async function s3Upload(file: File) {
  const filename = `${Date.now()}-${file.name}`;

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type,
  });

  const thing = stored as IStorageVaultResult;
  return thing.key;
}
