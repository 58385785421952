interface IAppConfig {
  s3: {
    REGION: string,
    BUCKET: string,
  },
  apiGateway: {
    REGION: string,
    URL: string,
  }, 
  apiGatewayGraphql: {
    REGION: string,
    URL: string,
  },
  cognito: {
    REGION: string,
    USER_POOL_ID: string,
    APP_CLIENT_ID: string,
    IDENTITY_POOL_ID: string,
  },
  MAX_ATTACHMENT_SIZE: number,
  STRIPE_PUBLISHABLE_KEY: string  
};

function stringOrThrow(a: string | undefined) {
  if (!!a && typeof a === "string") {
    return a;
  } else {
    throw new Error("Value is undefined.");
  }
};

const config : IAppConfig = {
  // Backend config
  s3: {
    REGION: stringOrThrow(process.env.REACT_APP_REGION),
    BUCKET: stringOrThrow(process.env.REACT_APP_BUCKET),
  },
  apiGateway: {
    REGION: stringOrThrow(process.env.REACT_APP_REGION),
    URL: stringOrThrow(process.env.REACT_APP_API_URL),
  },
  apiGatewayGraphql: {
    REGION: stringOrThrow(process.env.REACT_APP_REGION),
    URL: stringOrThrow(process.env.REACT_APP_GRAPHQL_URL)
  },
  cognito: {
    REGION: stringOrThrow(process.env.REACT_APP_REGION),
    USER_POOL_ID: stringOrThrow(process.env.REACT_APP_USER_POOL_ID),
    APP_CLIENT_ID: stringOrThrow(process.env.REACT_APP_USER_POOL_CLIENT_ID),
    IDENTITY_POOL_ID: stringOrThrow(process.env.REACT_APP_IDENTITY_POOL_ID),
  },
  MAX_ATTACHMENT_SIZE: 5000000,
  STRIPE_PUBLISHABLE_KEY: stringOrThrow(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
};

export default config;
