import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
import NewNote from "./containers/NewNote";
import Note from "./containers/Note";
import Signup from "./containers/Signup";
import Settings from "./containers/Settings";
import NewFolder from "./containers/NewFolder";
import Folder from "./containers/Folder";
import { Playground} from 'graphql-playground-react';
import config from './config';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/documents">
        <Home />
      </Route>
      <Route exact path="/folders">
        <NewFolder />
      </Route>
      <Route exact path="/folders/new">
        <NewFolder />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/signup">
        <Signup />
      </Route>
      <Route exact path="/settings">
        <Settings />
      </Route>
      <Route exact path="/notes/new">
        <NewNote />
      </Route>
      <Route exact path="/notes/:id">
        <Note />
      </Route>
      <Route exact path="/folders/:id">
        <Folder />
      </Route>
      <Route exact path="/playground">
        <Playground endpoint={config.apiGatewayGraphql.URL} />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
