import { useState, useMemo } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import Button from "react-bootstrap/Button";

export default function MemoizedPdf(props: {url: string}) {
    const [numPages, setNumPages] = useState<number>(0)
    const [pageNumber, setPageNumber] = useState<number>(1);

    function onLoadSuccess(params: { numPages: number }) {
        console.log(params)
        setNumPages(params.numPages)
    }

    function pageUp() {
        const nextPage = pageNumber + 1;
        if (nextPage <= numPages) {
            setPageNumber(nextPage)
        }
    }

    function pageDown() {
        const nextPage = pageNumber - 1;
        if (nextPage > 0) {
            setPageNumber(nextPage)
        }
    }

    function fetchDocumentData() {
        return props.url;
    }

    // eslint-disable-next-line
    const memoizedUrl = useMemo(() => fetchDocumentData(), [fetchDocumentData, props.url])

    return (
        <>
      <div className="d-flex justify-content-center align-items-center flex-fill w-50">
        <Button className="w-50" variant="primary" onClick={pageDown} >Previous</Button>
        <Button className="w-50" variant="primary" onClick={pageUp} >Next</Button>
      </div>
        <Document file={memoizedUrl} onLoadSuccess={onLoadSuccess} onLoadProgress={console.log}>
            <Page pageNumber={pageNumber}></Page>
        </Document>
        </>
    )
}