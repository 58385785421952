import ListGroup from "react-bootstrap/ListGroup";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { Spinner } from "react-bootstrap";

export interface INote {
    id: string;
    content: string;
    attachment: string;
    filename: string;
    createdAt: string;
}

interface INotesListProps {
    isLoading: boolean;
    notes: INote[];
    folderId?: string;
}

function NotesList({ isLoading, notes }: INotesListProps) {

    function renderNote(note: INote) {
        return (
            <LinkContainer key={note.id} to={`/notes/${note.id}`}>
            <ListGroup.Item
              className="d-flex flex-column justify-content-between align-items-start"
              action
            >
              <div className="d-flex justify-context-between">
              <span className="ms-2 me-auto font-weight-bold">
                {note.content.trim().split("\n")[0]}
              </span>
              <br />
              <span className="ms-2 me-auto text-muted">
                Created: {new Date(note.createdAt).toLocaleString()}
              </span>
              </div>
              <span className="ms-2 me-auto text-body">
                {note.filename}
              </span>
            </ListGroup.Item>
          </LinkContainer>
        );
    }

    if (isLoading) {
        return (<Spinner animation="border"></Spinner>)
    }
    return (
        <ListGroup>
            <LinkContainer to="/notes/new">
              <ListGroup.Item action className="py-3 text-nowrap text-truncate">
                <BsPencilSquare size={17} />
                <span className="ml-2 font-weight-bold">Create a new note</span>
              </ListGroup.Item>
            </LinkContainer>
            {notes.map(renderNote)}
        </ListGroup>
    )
}

export default NotesList;
