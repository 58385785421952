import React, { FormEvent, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../lib/errorLib";
import "./NewFolder.css";
import { API } from "aws-amplify";

export default function NewFolder() {
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [defaultDocumentType, setDefaultDocumentType] = useState<string>("document");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return title && title.length > 0;
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await createFolder({ title, defaultDocumentType })
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="NewFolder">
      <Form onSubmit={handleSubmit}>
        <InputGroup className="mb-3">
          <InputGroup.Text>Title</InputGroup.Text>
          <Form.Control value={title} onChange={(e) => setTitle(e.target.value)} />
        </InputGroup>
        <InputGroup>
        <InputGroup.Text>Default Document Type</InputGroup.Text>
        <Form.Select aria-label="Default Document Type Select" value={defaultDocumentType} onChange={(e) => setDefaultDocumentType(e.target.value)} >
          <option value="document">Digital Documents (PDFs, etc...)</option>
          <option value="photo">Photos</option>
          <option value="scanned">Scanned Documents (e.g., mail)</option>
          <option value="folder">Folder</option>
        </Form.Select>
        </InputGroup>
        <LoaderButton
          block
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </Form>
    </div>
  );
}

interface IFolderSubmit {
  title: string,
  defaultDocumentType: string
}

async function createFolder(folder: IFolderSubmit) {
  return API.post("notes", "/folders", {
    body: folder
  });
}
