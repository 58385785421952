import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import { Auth } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import { useHistory } from "react-router-dom";
import { onError } from "../lib/errorLib";
import { useFormFields } from "../lib/hooksLib";
import { Modal, Button } from "react-bootstrap";

interface ILoginForm {
  email: string,
  password: string,
  code?: string
}

function Login() {
  const { userHasAuthenticated } = useAppContext();
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const [fields, handleFieldChange] = useFormFields<ILoginForm>({
    email: "",
    password: "",
    code: ""
  });

  function validateForm() {
    const base_fields = fields.email.length > 0 && fields.password.length > 0;
    if (forgotPassword) {
      return typeof fields.code !== undefined && fields.code?.length === 6 && base_fields;
    } else {
      return base_fields;
    }
    
  }

  async function handleSubmit(event: React.FormEvent<HTMLElement>) {
    event.preventDefault()
    setIsLoading(true);
    if (forgotPassword && typeof fields.code === 'string') {
      try {
        await Auth.forgotPasswordSubmit(fields.email, fields.code, fields.password)
        userHasAuthenticated(true);
        history.push("/")
      } catch (e) {
        onError(e);
        setIsLoading(false);
      }
    } else {
      try {
        await Auth.signIn(fields.email, fields.password);
        userHasAuthenticated(true);
        history.push("/");
      } catch (e) {
        onError(e);
        setIsLoading(false);
      }
    }
  }

  async function handleForgottenPasswordSubmit(event: React.FormEvent<HTMLElement>) {
    event.preventDefault();
    setForgotPassword(true);
    setIsLoading(true);
    try {
      await Auth.forgotPassword(fields.email);
      setShow(false);
      setIsLoading(false);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function handleClose() {
    setShow(false)
  }

  function handleOpen() {
    setShow(true);
  }

  function renderForgotPasswordForm() {

    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <Form onSubmit={handleForgottenPasswordSubmit}>
              <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoFocus
                type="email"
                value={fields.email}
                onChange={handleFieldChange}
              />
              </Form.Group>
              <div className="d-grip gap-2">
                <Button
                  type="submit"
                  size="lg"
                  >Submit</Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  return (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        { forgotPassword ? (<Form.Group className="mb-3" controlId="code">
        <Form.Label>Code</Form.Label>
        <Form.Control
          type="text"
          value={fields.code}
          onChange={handleFieldChange}
        />
        </Form.Group>) : null}        
        <Form.Group className="mb-3" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          value={fields.password}
          onChange={handleFieldChange}
        />
        </Form.Group>
        <div className="d-grid gap-2">
          <LoaderButton 
            block
            size="lg"
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Login
          </LoaderButton>
        </div>
        <Button onClick={handleOpen} >Forgot password</Button>
      </Form>
      {renderForgotPasswordForm()}
    </div>
  );
}

export default Login;
