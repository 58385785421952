import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { onError } from "../lib/errorLib";
import "./Folder.css";
import {Row, Col, Container, ListGroup, Spinner } from 'react-bootstrap';
import NotesList, { INote } from "../components/NotesList"

// import Row from 'react-bootstrap/Row';

interface DetailParams {
    id: string
}

interface IFolder {
  id: string;
  detail: {title: string}
}

export default function Note() {
  // eslint-disable-next-line
  const { id } = useParams<DetailParams>();

  // eslint-disable-next-line
  const history = useHistory();
  const [folder, setFolder] = useState<IFolder>();
  const [notes, setNotes] = useState<Array<INote>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    function loadFolder() {
      const init = {
        headers: {
          Accept: "application/json"
        }
      };
      return API.get("notes", `/folders/${id}`, init);
    }
    async function onLoad() {
      setIsLoading(true)
      try {
        const result : {folder: IFolder, notes: Array<INote>} = await loadFolder();
        setFolder(result.folder)
        setNotes(result.notes)
        console.log(JSON.stringify(result.folder))
        console.log(result.notes)
      } catch (e) {
        onError(e);
      }
      setIsLoading(false);
    }
    onLoad();
  }, [id]);

  function renderFolder() {
    return (
      <div className="Folder">
      <Container fluid>
        <h2>{folder?.detail.title}</h2>
        <Row>   
          <Col sm={12} md={8}>
            <NotesList isLoading={isLoading} notes={notes} />
          </Col>
          <Col sm={12} md={4}>
            <Row>
              <h3>Enrichments</h3>
              <ListGroup>
                <ListGroup.Item>OCR</ListGroup.Item>
              </ListGroup>
              <h3>Tags</h3>
              <ListGroup>
                <ListGroup.Item>Item1</ListGroup.Item>    
              </ListGroup>
            </Row>
          </Col>        
        </Row>
      </Container>
      </div>
    )
  }

  if (isLoading) {
    return <Spinner animation="border"></Spinner>
  }
  return <>{renderFolder()}</>
}