import React, { useContext, createContext } from "react";

interface AppContextInterface {
  isAuthenticated: boolean;
  userHasAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
}

export const AppContext = createContext<AppContextInterface>({} as AppContextInterface);

export function useAppContext() {
  return useContext(AppContext);
}
