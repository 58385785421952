import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import "./Home.css";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import NotesList, { INote } from "../components/NotesList";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { library } from "@fortawesome/fontawesome-svg-core";

interface IFolder {
  id: string;
  detail: any;
}

function Home() {
  const [notes, setNotes] = useState<INote[]>([]);
  const [folders, setFolders] = useState<IFolder[]>([]);

  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const notes = await loadNotes();
        setNotes(notes)
        const folders = await loadFolders();
        setFolders(folders);
      } catch (e) {
        onError(e);
      }
      setIsLoading(false);
    }
    onLoad();
  }, [isAuthenticated]);

  function loadNotes() {
    return API.get("notes", "/notes", {});
  }

  function loadFolders() {
    return API.get("notes", "/folders", {});
  }

  // function renderNotesList(notes: INote[]) {
  //  return (
  //    <NotesList isLoading={isLoading} notes={notes} />
  //  )
  // }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Folder</h1>
        <p className="text-muted">A simple document management app powered by AI.</p>
      </div>
    );
  }

  function renderFoldersList() {
    return folders.map(({id, detail}) => {
      return (
        <LinkContainer key={`${id}`} to={`/folders/${id}`}><ListGroup.Item action className="text-nowrap text-truncate">{detail.title}</ListGroup.Item></LinkContainer>
      )
    })
  }

  function renderFolders() {
    return (
    <Col sm={12} md={12} lg={3}>
      <div className="folders">
        <h3>Folders</h3>
        <ListGroup>
          {!isLoading && renderFoldersList()}
          <LinkContainer key={ "thing" } to={'folders/new'}><ListGroup.Item action className="text-nowrap text-truncate"><FontAwesomeIcon icon={faPlusSquare} /> Add Folder</ListGroup.Item></LinkContainer>
        </ListGroup>
      </div>
      </Col>);
  }
  function renderNotes() {
    return (
      <Container>

      <Row>

      <Col sm={12} md={12} lg={9}>
      <div className="notes">
        <h3>Recent Documents</h3>
        <NotesList isLoading={isLoading} notes={notes} />
      </div>
      </Col>
      {renderFolders()}
      </Row>
      </Container>
    );
  }

  function renderLoading() {
    return (
      <div>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  }

  function renderHome() {
    if (isAuthenticated && isLoading) {
      return renderLoading()
    }
    if (isAuthenticated && notes.length > 0) {
      return renderNotes()
    }
    return renderLander()
  }

  return (
    <div className="Home">
      {renderHome()}
    </div>
  );
}

export default Home;
