import React, { FormEvent, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../lib/errorLib";
import config from "../config";
import "./NewNote.css";
import { API } from "aws-amplify";
import { s3Upload } from "../lib/awsLib";
import ListGroup from "react-bootstrap/ListGroup"

export default function NewNote() {
  const file = useRef<HTMLFormElement | null>(null)

  const [fileList, setFileList] = useState<FileList>();
  const history = useHistory();
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return fileList && fileList.length > 0;
  }

  function handleFileChange(event: any) {
    console.log(event);
    file.current = event.target.files[0];
    setFileList(event.target.files);
  }

  function renderAttachment(attachment: File) {
    return <ListGroup.Item>{attachment.name} <span className="attachment-size">{attachment.size}</span> <span>{attachment.type}</span></ListGroup.Item>
  }
  function renderAttachmentList() {
    if (fileList && fileList.length > 0) {
      
      return (
        <>
        <ListGroup>
          {Array.from(fileList).map(renderAttachment)}
        </ListGroup>
        </>
      )
    }
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    let file: File | null = null;
    if (fileList && fileList.length > 0) {
      file = fileList[0]
    } else {
      throw new Error("")
    }
    if (file !== null && file && file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }
    setIsLoading(true);
    try {
      const filename = file.name;
      const attachment = await s3Upload(file);
      const comment = content && content.length > 0 ? content : file.name;
      await createNote({ content: comment, filename, attachment });
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="NewNote">
      <Form onSubmit={handleSubmit}>
        <InputGroup className="mb-3">
          <InputGroup.Text>Comment</InputGroup.Text>
          <Form.Control value={content} onChange={(e) => setContent(e.target.value)} />
        </InputGroup>
        <Form.Group controlId="file">
          <Form.Label>Attachment</Form.Label>
          <Form.Control onChange={handleFileChange} type="file" />
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </Form>
      {renderAttachmentList()}
    </div>
  );
}

interface INoteSubmit {
  content: string,
  attachment: string,
  filename: string
}

async function createNote(note: INoteSubmit) {
  return API.post("notes", "/notes", {
    body: note
  });
}
